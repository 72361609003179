export const categories = [
  "Footwear",
  "T-Shirts",
  "Sweaters & Hoodies",
  "Shirts",
  "Jeans",
  "Pants",
  "Jackets",
  "Shorts",
  "Underwear & Socks",
  "Accessories",
  "Other",
];
